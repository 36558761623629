const PartyMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-master/get`,
  POST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-master/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-master/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-search/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-master/download`,
  SEARCH: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-search/get`,
  UPLOAD : `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/party-master/upload`
};

export default PartyMasterApiUrls
