const DbAdminStatusConfigApiUrls = {
  GET: `${process.env.ORIGIN_URL}/db-admin-status-config/get`,
  POST: `${process.env.ORIGIN_URL}/db-admin-status-config/create`,
  PUT: `${process.env.ORIGIN_URL}/db-admin-status-config/update`,
  DELETE: `${process.env.ORIGIN_URL}/db-admin-status-config/delete`,
  EXPORT: `${process.env.ORIGIN_URL}/db-admin-status-config/download`,
  ACE_HIGHWAY_SEARCH_7512: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/db-admin/search7512`,
  ACE_HIGHWAY_SEARCH_INBOND: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/db-admin/searchInbond`,
  ACE_HIGHWAY_SEARCH_SHIPMENT: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/db-admin/searchShipment`,
  ACE_HIGHWAY_SEARCH_MANIFEST: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/db-admin/searchManifest`,
  ACE_HIGHWAY_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/db-admin/updateStatus`,
  ACI_HIGHWAY_SEARCH_MANIFEST: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/db-admin/searchManifest`,
  ACI_HIGHWAY_SEARCH_CARGO: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/db-admin/searchCargo`,
  ACI_HIGHWAY_SEARCH_ARRIVAL_CERT: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/db-admin/searchArrivalCert`,
  ACI_HIGHWAY_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/db-admin/updateStatus`,
  ACI_OCN_SEARCH_A6: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/db-admin/searchA6`,
  ACI_OCN_SEARCH_A6A: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/db-admin/searchA6A`,
  ACI_OCN_SEARCH_AM: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/db-admin/searchAM`,
  ACI_OCN_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/db-admin/updateStatus`,
  SHARED_SEARCH_CREW_MASTER: ` ${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/db-admin/searchCrewMaster`,
  SHARED_SEARCH_EQUIPMENT_MASTER: ` ${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/db-admin/searchEquipmentMaster`,
  SHARED_SEARCH_CONVEYANCE_MASTER: ` ${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/db-admin/searchConveyanceMaster`,
  SHARED_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/db-admin/updateStatus`,
  EHBL_HOUSE_BILL_SEARCH: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/db-admin/searchHouseBill`,
  EHBL_CLOSE_MSG_SEARCH: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/db-admin/searchCloseMessage`,
  EHBL_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/db-admin/updateStatus`,
  FDA_PN_SEARCH: ` ${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/db-admin/searchFdaPn`,
  FDA_PN_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/db-admin/updateStatus`,
  SHARED_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/db-admin/updateStatus`,
  ACE_ISF5_SEARCH: ` ${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/db-admin/searchISF5`,
  ACE_ISF10_SEARCH: ` ${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/db-admin/searchISF10`,
  ACE_ISF_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/db-admin/updateStatus`,
  ACE_OCN_MANIFEST_SEARCH: ` ${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/db-admin/searchManifest`,
  ACE_OCN_BOL_SEARCH: ` ${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/db-admin/searchBol`,
  ACE_OCN_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/db-admin/updateStatus`,
  ACI_AIR_SEARCH_CONVEYANCE: ` ${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/db-admin/searchConveyance`,
  ACI_AIR_SEARCH_HEADER: ` ${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/db-admin/searchHeader`,
  ACI_AIR_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/db-admin/updateStatus`,
  AES_SEARCH: ` ${process.env.REACT_APP_USER_AES_URL}/aes/db-admin/search`,
  AES_UPDATE_STATUS: ` ${process.env.REACT_APP_USER_AES_URL}/aes/db-admin/updateStatus`,
};

export default DbAdminStatusConfigApiUrls
