const ISF10ApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/get`,
  POST: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/submit`,
  CLONE: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/clone`,
  PRINTISF10: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf10/printISF10`,
};


export default ISF10ApiUrls
