const AciAirResponseApiUrls = {
    GET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cusres/get`,
    POST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cusres`,
    D4_POST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/d4/cusres`,
    D4_GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/d4Notices/get`,
    RELEASE_NOTICE_GET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/release-notice/get`,
    GET_AUDIT_LOGS: `${process.env.REACT_APP_USER_ACIHW_URL}/cng-admin/audit-header/get`,
    PRINTD4: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/d4Notices/printD4Report`
  };
  
  export default AciAirResponseApiUrls;
  