const NBCICanadaInvoiceApiUrls = {
  GET: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/get`,
  POST: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/create`,
  PUT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/update`,
  DELETE: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/delete`,
  EXPORT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/download`,
  EXPORT_CSV: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/downloadCSV`,
  UPLOAD: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/upload`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  SUBMIT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/submit`,
  CLONE: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/clone`,
  RESPONSE_GET: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/cusres/get`,
  PRINT_BOL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printBol`,
  PRINT_USMCACUSMA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printUsmcaCusma`,
  PRINT_PL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printPackingList`,
  PRINT_B13A: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printB13AForm`,
  PRINT_CFIA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printCFIAForm`,
  PRINT_IMPORT_DECLARTION: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printImportDeclForm`,
  PRINT_CCI: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/ca-invoice/printCCIForm`
};

export default NBCICanadaInvoiceApiUrls
