const AceOcnBillofladingApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/get`,
  POST: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/download`,
  EXPORT_BOL: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/exportBol`,
  CLONE: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/clone`,
  PRINTBOL: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/printBillOfLading`,
  PRINTRESPONSE: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/billoflading/printResposne`
};

export default AceOcnBillofladingApiUrls
