const ConveyanceApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/get`,
  POST: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/download`,
  CLONE: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/clone`,
  SUBMIT: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/conveyance/submitAll`,
  SEARCH: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/aci-air-conv-search/get`
};

export default ConveyanceApiUrls
