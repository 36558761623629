const H2hSubFailsApiUrls = {
  GET: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/h2h-sub-fails/get`,
  POST: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/h2h-sub-fails/create`,
  PUT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/h2h-sub-fails/update`,
  DELETE: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/h2h-sub-fails/delete`,
  EXPORT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/h2h-sub-fails/download`,
  DOWNLOAD_ACK:  `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/h2h-sub-fails/downloadAttachment`
};

export default H2hSubFailsApiUrls
