const EhblCloseMsgApiUrls = {
  GET: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/get`,
  POST: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/create`,
  PUT: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/update`,
  DELETE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/delete`,
  EXPORT: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/download`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/submitAll`,
  GET_RESPONSE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/getResponse`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  PRINT_CLOSE_MESSAGE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/close-msg/printCloseMsgReport`
};

export default EhblCloseMsgApiUrls
