const AciHighwayCargoApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/get`,
  POST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/create`,
  PUT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/update`,
  DELETE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/delete`,
  MASS_DELETE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/mass-delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/download`,
  CLONE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/clone`,
  SUBMIT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/submit`,
  UPLOAD: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/upload`,
  DELETE_ALL : `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/deleteAll`,
  PRINT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/printReport`,
  BULK_PRINT_A8A: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/bulkPrintA8AReport`,
  PRINTA49: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/printA49Report`,
  GET_CARGO_LOADED: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/getCargoLoadedIn`,
  SN_CCN_SEARCH: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/getSNCCN`,
  GET_CARGO_BY_MANIFEST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/getCargoIdByManifestId`,
  GET_CARGO_QUICK_GLANCE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo-quick-glance/get`,
  GET_COUNT_BY_STATUS: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/get-count-by-status`,
  CUSTOM_SEARCH: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo-custom-search/get`,
  EXPORT_CARGO: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/cargo/exportCargo`,
};

export default AciHighwayCargoApiUrls
