const AesProductMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_AES_URL}/aes/product-master/get`,
  POST: `${process.env.REACT_APP_USER_AES_URL}/aes/product-master/create`,
  PUT: `${process.env.REACT_APP_USER_AES_URL}/aes/product-master/update`,
  DELETE: `${process.env.REACT_APP_USER_AES_URL}/aes/product-master/delete`,
  EXPORT: `${process.env.REACT_APP_USER_AES_URL}/aes/product-master/download`,
  CLONE: `${process.env.REACT_APP_USER_AES_URL}/aes/product-master/clone`
};

export default AesProductMasterApiUrls
