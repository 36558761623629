const AesShipmentApiUrls = {
  GET: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/get`,
  SEARCH: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment-search/get`,
  POST: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/create`,
  PUT: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/update`,
  DELETE: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/delete`,
  EXPORT: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment-search/download`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/submitAll`,
  CLONE: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/clone`,
  PRINT_SHIPMENT: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/printReport`,
  UPLOAD: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/upload`,
  SEARCH_PARTY_SCREENING: `${process.env.REACT_APP_USER_AES_URL}/aes/shipment/searchPartyScreening`,
};

export default AesShipmentApiUrls
