const NaCodeMaintenanceApiURLs = {
  ROOT: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/na`,
  GET_NOT_IN_NA: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/get`,
  GET: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/na/get`,
  POST: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/na/create`,
  PUT: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/na/update`,
  DELETE: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/na/delete`,
  EXPORT: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/na/download` 
};

export default NaCodeMaintenanceApiURLs
