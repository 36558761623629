const AceOcnVesselDepartureApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-departure/get`,
  POST: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-departure/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-departure/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-departure/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-departure/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-departure/submit`
};

export default AceOcnVesselDepartureApiUrls
