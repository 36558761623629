const UsProductMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/get`,
  POST: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/create`,
  PUT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/update`,
  DELETE: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/download`,
  CLONE: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/clone`,
  UPLOAD: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/upload`,
  UPLOADCA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-product-master/uploadCA`,
  UPLOADPARTY: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/usca-party-master/upload`,
  UPLOADPARTYCA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/usca-party-master/uploadCA`,
};

export default UsProductMasterApiUrls
