const ConveyanceMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/get`,
  POST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/download`,
  EXPORT_CSV: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/downloadCSV`,
  UPLOAD : `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/conveyance-master/upload`
};

export default ConveyanceMasterApiUrls
