import ProductMasterRoutes from "src/routes/ProductMasterRoute"
import PartyMasterRoutes from "src/routes/PartyMasterRoute"
import ArrivalMessageRoutes from "src/routes/ArrivalmessageRoute"
import ArrivalResponseRoutes from "src/routes/ArrivalResponseRoute"
import UploadResposeRoutes from "src/routes/UploadResponseRoute"
import UserProfileRoutes from "src/routes/UserProfileRoute"
import CommunicationUtilRoute from "src/routes/CommunicationUtilRoute"
import BillingConfigRoute from "src/routes/BillingConfigRoute"
import AciAirAcrRoutes from "src/routes/AciAirAcrRoute"
import AciAirSsrRoutes from "src/routes/AciAirSsrRoute"
import AciAirS10Routes from "src/routes/AciAirS10Route"
import a6Routes from "src/routes/AcioceanRoute"
import Conveyance from "src/routes/ConveyanceRoute"
import A6aRoute from "src/routes/A6aRoute"
import AirArrivalRoute from "src/routes/AirArrivalRoute"
import DocGenPurchaseOrderRoute from "src/routes/DocGenPurchaseOrderRoute"
import StatusMessageRoutes from "src/routes/StatusMessageRoute"
import DocGenUploadRoute from "src/routes/DocGenUploadRoute"
import H2hSubFailsRoute from "src/routes/H2hSubFailsRoute"
import FileUserRoute from "src/routes/FileUserRoute"
import FileForUserRoute from "src/routes/FileForUserRoute"
import InvoiceRoute from "src/routes/InvoiceRoute"
import FlightMatrixRoute from "src/routes/FlightMatrixRoute"
import ValidationErrorLogRoute from "src/routes/ValidationErrorLogRoute"
import HSCodeRoute from "src/routes/HSCODERoute"
import DBAdminAciAirRoute from "src/routes/DBAdminAciAirRoute"
import SbciInvoiceRoute from "src/routes/SbciInvoiceRoute"
import SbciProductMasterRoute from "src/routes/SbciProductMasterRoute"
import SbciManifestRoute from "src/routes/SbciManifestRoute"
import SBCIUploadRoute from "src/routes/SBCIUploadRoute"
import SbciFileUploadHistoryRoute from "src/routes/SbciFileUploadHistoryRoute"
import EquipmentMasterRoute from "src/routes/EquipmentMasterRoute"
import ConveyanceMasterRoute from "src/routes/ConveyanceMasterRoute"
import AciHighwayStatusQueryRoute from "src/routes/AciHighwayStatusQueryRoute"
import CrewMasterRoute from "src/routes/CrewMasterRoute";
import UsProductMasterRoute from "src/routes/UsProductMasterRoute";
import AciHighwayCargoRoute from "src/routes/AciHighwayCargoRoute";
import AciHighwayManifestRoute from "src/routes/AciHighwayManifestRoute"
import AciHighwayD4NoticeRoute from "src/routes/AciHighwayD4NoticeRoute"
import AciHwReleaseNoticeRoute from "src/routes/AciHwReleaseNoticeRoute"
import AciHighwayArrivalRoute from "src/routes/AciHighwayArrivalRoute";
import UploadMasterDataRoute from "src/routes/UploadMasterDataRoute";
import CargoUploadRoute from "src/routes/CargoUploadRoute";
import AceHighwayShipmentRoute from "src/routes/AceHighwayShipmentRoute";
import AceHighwayManifestRoute from "src/routes/AceHighwayManifestRoute";
import AceHwyInbondRoute from "src/routes/AceHwyInbondRoute";
import AciHighwayInbondRoute from "src/routes/ArrivalCertRoute";
import DashboardRoute from "src/routes/DashboardRoute";
import DbAdminStatusConfigRoute from "src/routes/DbAdminStatusConfigRoute"
import NbciUSCAPartyMasterRoute from  "src/routes/NBCIUSCAPartyMasterRoute";
import UsDeclarationsRoute from "src/routes/UsDeclarationsRoute"
import FaxTrackerRoute from "src/routes/FaxTrackerRoute"
import NBCICanadaInvoiceRoute from "src/routes/NBCICanadaInvoiceRoute"
import NbciUsInvoiceRoute from "src/routes/NbciUsInvoiceRoute";
import HomePageAboutUsRoute from "src/routes/HomePageAboutUsRoute";
import HomePageContactUsRoute from "src/routes/HomePageContactUsRoute";
import HomePageProductInfoRoute from "src/routes/HomePageProductInfoRoute";
import HomePageTradeResourcesRoute from "src/routes/HomePageTradeResourcesRoute.js";
import HomePageFAQRoute from "src/routes/HomePageFAQRoute";
import HomePagePrivacyPolicyRoute from "src/routes/HomePagePrivacyPolicyRoute"
import HomePageTermsOfUseRoute from "src/routes/HomePageTermsOfUseRoute"
import HomePageTermsOfServicesRoute from "src/routes/HomePageTermsOfServicesRoute"
import AnnouncementRoute from "src/routes/AnnouncementRoute"
import AceHwyAbiInbond7512Route from "src/routes/AceHwyAbiInbond7512Route"
import AceHwyAbiInbondEventRoute from "src/routes/AceHwyAbiInbondEventRoute"
import EhblManifestRoute from "src/routes/EhblManifestRoute"
import EhblHouseBillRoute from "src/routes/EhblHouseBillRoute"
import EhblCloseMsgRoute from "src/routes/EhblCloseMsgRoute"
import EhblManifestForwardRoute from "src/routes/EhblManifestForwardRoute"
import WACMManifestForwardRoute from "src/routes/WACMManifestForwardRoute"
import EhblD4NoticeRoute from "src/routes/EhblD4NoticeRoute"
import WACMD4NoticeRoute from "src/routes/WACMD4NoticeRoute"
import AceISF10Route from "src/routes/AceISF10Route"
import AceISF5Route from "src/routes/AceISF5Route"

import AceOcnManifestRoute from "src/routes/AceOcnManifestRoute"
import AceOcnBillofladingRoute from "src/routes/AceOcnBillofladingRoute"
import AciOceanArrivalMessageRoute from "src/routes/AciOceanArrivalMessageRoute"
import AciAirArrivalMessageRoute from "src/routes/AciAirArrivalMessageRoute"
import AceOcnVesselMasterRoute from "src/routes/AceOcnVesselMasterRoute"
import AceOcnVesselArrivalRoute from "src/routes/AceOcnVesselArrivalRoute"
import AceOcnVesselDepartureRoute from "src/routes/AceOcnVesselDepartureRoute"
import PartyConfigRoute from "src/routes/PartyConfigRoute" 
import FDAPNRoute from "src/routes/FdapnRoute"
import FdapnProductMasterRoute from "src/routes/FdapnProductMasterRoute"
import AesShipmentRoute from "src/routes/AesShipmentRoute" 
import AesPartyScreeningRoute from "src/routes/AesPartyScreeningRoute"
import AesProductMasterRoute from "src/routes/AesProductMasterRoute"


const routes = [
  new SBCIUploadRoute(),
  new SbciManifestRoute(),
  new SbciProductMasterRoute(),
  new SbciInvoiceRoute(),
  new SbciFileUploadHistoryRoute(),
  new DocGenUploadRoute(),
  new ProductMasterRoutes(),
  new PartyMasterRoutes(),
  new ArrivalMessageRoutes(),
  new ArrivalResponseRoutes(),
  new UploadResposeRoutes(),
  new UserProfileRoutes(),
  new CommunicationUtilRoute(),
  new BillingConfigRoute(),
  new AciAirAcrRoutes(),
  new AciAirSsrRoutes(),
  new AciAirS10Routes(),
  new a6Routes(),
  new Conveyance(),
  new A6aRoute(),
  new AirArrivalRoute(),
  new DocGenPurchaseOrderRoute(),
  new StatusMessageRoutes(),
  new H2hSubFailsRoute(),
  new FileUserRoute(),
  new FileForUserRoute(),
  new InvoiceRoute(),
  new FlightMatrixRoute(),
  new ValidationErrorLogRoute(),
  new HSCodeRoute(),
  new DBAdminAciAirRoute(),
  new EquipmentMasterRoute(),
  new ConveyanceMasterRoute(),
  new AciHighwayStatusQueryRoute(),
  new CrewMasterRoute(),
  new AciHighwayManifestRoute(),
  new UsProductMasterRoute(),
  new AciHighwayCargoRoute(),
  new AciHighwayD4NoticeRoute(),
  new AciHwReleaseNoticeRoute(),
  new AciHighwayArrivalRoute(),
  new UploadMasterDataRoute(),
  new CargoUploadRoute(),
  new AceHighwayShipmentRoute(),
  new AceHighwayManifestRoute(),
  new AceHwyInbondRoute(),
  new AciHighwayInbondRoute(),
  new DashboardRoute(),
  new DBAdminAciAirRoute(),
  new DbAdminStatusConfigRoute(),
  new NbciUSCAPartyMasterRoute(),
  new UsDeclarationsRoute(),
  new FaxTrackerRoute(),
  new NBCICanadaInvoiceRoute(),
  new NbciUsInvoiceRoute(),
  new HomePageAboutUsRoute(),
  new HomePageContactUsRoute(),
  new HomePageProductInfoRoute(),
  new HomePageTradeResourcesRoute(),
  new HomePageFAQRoute(),
  new HomePagePrivacyPolicyRoute(),
  new HomePageTermsOfUseRoute,
  new HomePageTermsOfServicesRoute(),
  new AnnouncementRoute(),
  new AceHwyAbiInbond7512Route(),
  new AceHwyAbiInbondEventRoute(),
  new EhblManifestRoute(),
  new EhblHouseBillRoute(),
  new EhblCloseMsgRoute(),
  new EhblManifestForwardRoute(),
  new WACMManifestForwardRoute(),
  new EhblD4NoticeRoute(),
  new WACMD4NoticeRoute(),
  new AciOceanArrivalMessageRoute(),
  new AciAirArrivalMessageRoute(),
  new AceISF10Route(),
  new AceISF5Route(),
  new AceOcnManifestRoute(),
  new AceOcnBillofladingRoute(),
  new AceOcnVesselMasterRoute(),
  new AceOcnVesselDepartureRoute(),
  new AceOcnVesselArrivalRoute(),
  new PartyConfigRoute(),
  new AesShipmentRoute(),
  new AesPartyScreeningRoute(),
  new AesProductMasterRoute(),
  new PartyConfigRoute(),
  new FDAPNRoute(),
  new FdapnProductMasterRoute()
]

class CngRoute {
  getConfigurations() {
    return getChildRouteConfigurations()
  }
}

function getChildRouteConfigurations() {
  return routes.reduce((previousRoutes, route) => {
    let currentRoutes = previousRoutes.concat(route.getConfigurations())
    return currentRoutes
  }, [])
}

export default CngRoute
