const SbciManifestApiUrls = {
  GET: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/get`,
  POST: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/create`,
  PUT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/update`,
  DELETE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/delete`,
  EXPORT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/download`,
  UPLOAD : `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/upload`,
  SUBMIT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/submit`,
  RESPONSE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/response`,
  ATTACH: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/attach`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/submitAll`,
  SEARCH_ALL_INVOICE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifest/searchAllInvoice`,
  MANIFEST_BASE_GET: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-manifest-search/get`,
  MANIFEST_BASE_POST: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-manifest-search/create`,
  MANIFEST_BASE_PUT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-manifest-search/update`,
  MANIFEST_BASE_ATTACH: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-manifest-search/attachInvoice`,
  MANIFEST_BASE_DETACH: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-manifest-search/detachInvoice`,
  MANIFEST_BASE_CLONE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-manifest-search/clone`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`
};

export default SbciManifestApiUrls
