const DocGenPurchaseOrderApiUrls = {
  GET: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/get`,
  POST: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/create`,
  PUT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/update`,
  DELETE: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/delete`,
  EXPORT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order-search/download`,
  DOWNLOAD:  `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/downloadAttachment`,
  UPLOAD : `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/upload`,
  SEARCH: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order-search/get`,
  CREATE_INVOICE: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/purchase-order/createInvoice`,
};

export default DocGenPurchaseOrderApiUrls
