const StatusMessageApiUrls = {
  GET: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/statusmessage/get`,
  POST: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/statusmessage/create`,
  PUT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/statusmessage/update`,
  DELETE: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/statusmessage/delete`,
  EXPORT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/statusmessage/download`,
  DOWNLOAD:  `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/statusmessage/downloadAttachment`
};

export default StatusMessageApiUrls
