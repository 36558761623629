const AceHighwayApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/get`,
  POST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/deleteAll`,
  MASS_DELETE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/mass-delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/download`,
  CLONE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/clone`,
  SEARCH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment-search/get`,
  UPLOAD: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/upload`,
  ATTACH_SEARCH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment-attach-search/get`,
  ATTACH_SEARCH_DETAILS: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment-attach-search/attachShipmentDetails`,
  SN_SCN_SEARCH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/snGetSCN`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/submitAll`,
  GET_COUNT_BY_STATUS: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/get-count-by-status`,
  GET_SHIPMENT_QUICK_GLANCE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment-quick-glance/get`,
  CUSTOM_SEARCH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment-custom-search/get`,
  CUSTOM_SEARCH_INBOND: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment-custom-search-inbond/get`,
  CANCEL: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/cancel`,
  EXPORT_SHIPMENT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/exportShipment`,
  UPLOAD_XML: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/upload-xml`,
  GET_PROPAGATE_SHIPMENT_INFO: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/getPropagateShipment`,
  UPDATE_PROPAGATE_SHIPMENT_INFO: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/updatePropagateShipments`,
  GET_PROPAGATE_INBOND_NUMBER: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/shipment/getPropogateInbondNumber`,
};

export default AceHighwayApiUrls
