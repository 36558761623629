const SbciProductMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/get`,
  POST: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/create`,
  PUT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/update`,
  DELETE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/delete`,
  EXPORT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/download`,
  UPLOAD : `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/upload`,
  CLONE : `${process.env.REACT_APP_USER_SBCI_URL}/sbci/productmaster/clone`
};

export default SbciProductMasterApiUrls
