const AciAirApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/get`,
  POST: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/download`,
  CLONE: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/clone`,
  SUBMIT: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/ssr/submitAll`,
  SEARCH: `${process.env.REACT_APP_USER_ACI_AIR_URL}/aci-air/aci-air-search/get`
};

export default AciAirApiUrls
