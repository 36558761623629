const AcioceanApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/get`,
  POST: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/download`,
  CLONE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/clone`,
  PRINT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/printReport`,
  DOWNLOADXML: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/downloadXml`,
  SUBMIT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6/submitAll`,
  GET_D4: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/d4notice/get`,
  POST_D4: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/d4notice/create`
};

export default AcioceanApiUrls
