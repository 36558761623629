const InvoiceApiUrls = {
  GET: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/get`,
  POST: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/create`,
  PUT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/update`,
  DELETE: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice-search/download`,
  SUBMIT: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice/submitAll`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  SEARCH: `${process.env.REACT_APP_USER_DOC_GEN_URL}/doc-gen/invoice-search/get`,
};

export default InvoiceApiUrls
