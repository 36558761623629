const FdapnApiUrls = {
  GET: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/get`,
  POST: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/create`,
  PUT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/update`,
  DELETE: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/delete`,
  EXPORT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/download`,
  SUBMIT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/submit`,
  CLONE: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/product-master/clone`
};

export default FdapnApiUrls
