const ArrivalmessageApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/get`,
  POST: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/download`,
  CLONE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/clone`,
  SUBMIT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/arrivalmessage/submitAll`
};

export default ArrivalmessageApiUrls
