const A6aApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/get`,
  POST: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/download`,
  CLONE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/clone`,
  SUBMIT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/submitAll`,
  GET_D4: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/d4Notices/get`,
  POST_D4: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/d4notice/create`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  PRINT: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/printReport`,
  PRINTA10: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/printReportA10`,
  PRINTA8A: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/printReportA8A`,
  DOWNLOADA6AXML: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/a6a/downloadXml`
  };

export default A6aApiUrls
