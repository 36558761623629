const AciHighwayStatQryApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/get`,
  POST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/create`,
  PUT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/update`,
  DELETE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/submit`,
  DELETE_ALL : `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/status-query/deleteAll`,
};

export default AciHighwayStatQryApiUrls
