const ArrivalCertApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/get`,
  POST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/create`,
  PUT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/update`,
  DELETE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/submitAll`,
  CLONE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/clone`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/deleteAll`,
  UPLOAD: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/inbond/upload`,
};

export default ArrivalCertApiUrls
