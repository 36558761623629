const SbciManifestResponseApiUrls = {
  GET: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifestresponse/response/get`,
  POST: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifestresponse/create`,
  PUT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifestresponse/update`,
  DELETE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifestresponse/delete`,
  EXPORT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifestresponse/download`,
  DOWNLOAD: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/manifestresponse/response/download`
};

export default SbciManifestResponseApiUrls
