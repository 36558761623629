const CrewMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/get`,
  POST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/download`,
  EXPORT_CSV: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/downloadCSV`,
  SEARCH: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-search/get`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/deleteAll`,
  UPLOAD : `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/crew-master/upload`
};

export default CrewMasterApiUrls
