const EhblManifestApiUrls = {
  GET: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/get`,
  POST: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/create`,
  PUT: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/update`,
  DELETE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/delete`,
  EXPORT: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/download`,
  ATTACH: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/attach`,
  DETACH: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/detach`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/submitAll`,
  PROPAGATE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/propagate`,
  UPLOAD: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/manifest/upload`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`
};

export default EhblManifestApiUrls
