const AceHighwayAbiInbond7512ApiUrls = {
  GET: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/get`,
  SEARCH: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512-search/get`,
  POST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/submit`,
  CLONE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/clone`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  PRINT_ABI_INBOND: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/printAbiInbond`,
  UPLOAD: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond-7512/upload`,
};

export default AceHighwayAbiInbond7512ApiUrls
