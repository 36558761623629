const ISF5ApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/get`,
  POST: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/submit`,
  CLONE: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/clone`,
  PRINTISF5: `${process.env.REACT_APP_USER_ACE_ISF_URL}/ace-isf/isf5/printISF5`,
};


export default ISF5ApiUrls
