const HSCODEApiUrls = {
  ROOT: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/hscode`,
  GET: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/hscode/get`,
  POST: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/hscode/create`,
  PUT: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/hscode/update`,
  DELETE: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/hscode/delete`,
  EXPORT: `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance/hscode/download` 
}; 

export default HSCODEApiUrls
