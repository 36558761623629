const AceHwyInbondArrivalApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/get`,
  POST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/download`,
  CLONE:`${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/clone`,
  DELETE_ALL : `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/deleteAll`,
  SUBMIT : `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/submit`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  UPLOAD: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/inbond/upload`,
};

export default AceHwyInbondArrivalApiUrls
