const AceHighwayManifestApiUrls = {
  GET: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/get`,
  CUSTOM_SEARCH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest-custom-search/get`,
  CUSTOM_EXPORT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest-custom-search/download`,
  GET_DETAILS: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest-details/get`,
  SEARCHPORT: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest-search/getManifestPort`,
  CUSTOM_UPDATE: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest-search/update`,
  POST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/delete`,
  DELETE_ALL: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/download`,
  ATTACH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/attach`,
  DETACH: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/detach`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/submit`,
  SUBMIT_PAXLST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/submitPAXLST`,
  CLONE: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/clone`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  PRINT_MANIFEST_SHIPMENT: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/printManifestShipment`,
  PRINT_MANIFEST_COVERSHEET: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/printManifestCoverSheet`,
  PRINT_MANIFEST_COVERSHEET_SUMMARY: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/printManifestCoverSheetSummary`,
  PRINT_7533: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/printManifest7533`,
  PRINT_7521: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/printManifest7521`,
  EMAIL_COVERSHEET: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/emailManifestCoverSheet`,
  FAX_COVERSHEET: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/faxManifestCoverSheet`,
  EXPORT_MANIFEST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/exportManifest`,
  CREATE_ACI_MANIFEST: `${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/manifest/createACIManifest`,
};

export default AceHighwayManifestApiUrls
