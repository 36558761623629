const EhblHouseBillApiUrls = {
  GET: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/get`,
  POST: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/create`,
  PUT: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/update`,
  DELETE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/delete`,
  MASS_DELETE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/mass-delete`,
  EXPORT: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/download`,
  CLONE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/clone`,
  GET_RESPONSE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/getResponse`,
  CREATE_CLOSE_MESSAGE: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/createCloseMessage`,
  UPLOAD: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/upload`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  PRINT_HBL: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/printHouseBillReport`,
  PRINT_A8A: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/printA8AReport`,
  PRINT_MASTER_BILL: `${process.env.REACT_APP_USER_EHBL_URL}/ehbl/house-bill/printMasterBill`
};

export default EhblHouseBillApiUrls
