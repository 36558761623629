const ProductMasterApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/product-master/get`,
  POST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/product-master/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/product-master/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/product-master/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/product-master/download`,
  UNDG_SEARCH: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/product-master/searchUndgs`,
};

export default ProductMasterApiUrls
