const AceOcnVesselArrivalApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-arrival/get`,
  POST: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-arrival/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-arrival/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-arrival/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-arrival/download`,
  SUBMIT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/vessel-arrival/submit`
};

export default AceOcnVesselArrivalApiUrls
