const AnnouncementApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/announcement/get`,
  POST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/announcement/create`,
  PUT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/announcement/update`,
  DELETE: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/announcement/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/announcement/download`,
  GET_ANNOUNCEMENT_BY_GROUP: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/announcement/get-announcement-by-group`
};

export default AnnouncementApiUrls
