const AciHighwayManifestApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/get`,
  POST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/create`,
  PUT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/update`,
  DELETE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/delete`,
  DELETE_ALL : `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/deleteAll`,
  EXPORT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/download`,
  SEARCH: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest-search/get`,
  CUSTOM_UPDATE: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest-search/update`,
  CLONE: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/clone`,
  SUBMIT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/submitAll`,
  ATTACH: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/attach`,
  DETACH: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/detach`,
  PRINT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/printReport`,
  PRINTCOVERSHEET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/printCoverSheet`,
  EMAIL_COVERSHEET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/emailManifestCoverSheet`,
  FAX_COVERSHEET: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/faxManifestCoverSheet`,
  CUSTOM_SEARCH: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest-custom-search/get`,
  CUSTOM_EXPORT: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest-custom-search/download`,
  EXPORT_MANIFEST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/exportManifest`,
  CREATE_ACE_MANIFEST: `${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/manifest/createACEManifest`,
};

export default AciHighwayManifestApiUrls
